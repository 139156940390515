import "./summary.scss";

import BlockContent from "@sanity/block-content-to-react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import { right_svg } from "../../assets/svg/controls_elements";
import ModalFormInfo from "../ModalFormInfo/ModalFormInfo";

const Summary: React.FC = () => {
  const { summary } = useStaticQuery(graphql`
    query Summary {
      summary: sanityPageHome {
        ctaText: summaryOfUsCtaText
        resume: _rawSummaryOfUsResume
      }
    }
  `);

  const { ModalForm, toggleModal } = ModalFormInfo();

  return (
    <>
      <ModalForm />
      <section className={"o-summary"}>
        <div className={"c-summary"}>
          <div className="m-summary__grid">
            <div className="m-summary__first">
              <p className={"m-summary__title"}> Grupo Usieto </p>
              <ul className={"m-summary__list"}>
                <li>Quiénes somos </li>
                <li>- Instalaciones</li>
              </ul>
              <div className="m-summary__seals">
                <a
                  href="/RSA_GRUPO_USIETO.PDF"
                  target="_blank"
                  rel="noreferrer"
                  title={"Descargar Memoria de Responsabilidad Social"}>
                  <StaticImage
                    src={"./rsa.jpg"}
                    alt={"Sello de entidad Responsabilidad Social de Aragón (RSA) 2023"}
                  />
                </a>
                <StaticImage
                  src={"./ceroCo.png"}
                  alt={"Sello cero CO2 calculado"}
                />
                <StaticImage
                  src={"./usicom.jpg"}
                  alt={"Logotipo app Usicom"}
                  className={"usicom-logo"}
                  height={106}
                />
                <StaticImage
                  src={"./IFS.png"}
                  alt={"Logotipo IFS"}
                />
              </div>
            </div>
            <div className="m-summary__second">
              <BlockContent blocks={summary.resume} />
              <button
                className={"m-button"}
                onClick={toggleModal}>
                {summary.ctaText} {right_svg}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Summary;
